
ul {
  li {
    display: flex;
    justify-content: space-between;
    flex: 1;

    div {
      // text-align: center;
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
