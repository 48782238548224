
.cls:hover .clsChild {
  background: rgba($color: #000000, $alpha: 0.4);
  display: block;
}
.clsText {
  overflow: hidden;
  text-overflow: ellipsis;
  // 只要超过宽度就换行，不论中文还是英文
  word-break: break-all;
  //最多展示两行
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.elf-upload-list-picture-card-container {
  margin: 0px 8px 40px 0px;
}
.elf-modal .elf-modal-footer {
  text-align: right;
  padding: 16px;
}
